export const ICONS = {
	success: "icon-success",
	failed: "icon-failed",
	timer: "icon-timer",
	cost: "icon-cost",
	sports: "icon-sports",
	calender: "icon-calender",
	noData: "icon-noData",
	offDays:"icon-offDays",
	mailSuccess:"icon-mailSuccess",
	mail:"icon-mail"
};

export type IconsType = (typeof ICONS)[keyof typeof ICONS];
